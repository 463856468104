<template>
  <div class="card">
    <div class="card-top" :class="hideLine ? 'hide-line' : ''">
      <div class="card-title">{{ title }}</div>
      <slot name="topRight"></slot>
    </div>
    <div class="card-main" :class="hideLine ? 'hide-line-main' : ''">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 标题
    title: {
      type: String,
      default: '',
    },
    // 是否显示顶部线
    hideLine: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 16px;

  .card-top {
    padding: 8px 24px;
    border-bottom: 1px solid #ebebeb;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .card-title {
      height: 32px;
      font-size: 16px;
      font-weight: 600;
      color: #3d3d3d;
      line-height: 32px;
    }
  }

  .hide-line {
    border: 0;
  }

  .card-main {
    padding: 16px 24px;
  }

  .hide-line-main {
    padding-top: 0;
  }
}
</style>
