<template>
  <div
    class="custom-date-picker-root"
    @mousedown.prevent
    style="width: 360px; display: flex;"
  >
    <div style="width: 100px; line-height: 22px; margin: 5px 0; border-right: solid 2px #eee; text-align: center;">选择时间</div>
    <a-range-picker 
    
      :show-time="{ format: 'HH:mm:ss' }"
      format="YYYY-MM-DD HH:mm:ss"
      v-model="datetime" 
      @change="onChange" 
      class="custom-date-picker" 
      :placeholder="['开始时间', '结束时间']" 
      dropdownClassName="custom-date-picker"
      separator=""
    >
      <a-icon slot="suffixIcon" type="calendar" />
    </a-range-picker>
    <!-- <a-month-picker v-model="datetime" @change="onChange" class="custom-date-picker" placeholder="请选择" dropdownClassName="custom-date-picker" /> -->
  </div>
</template>
<script>
export default {
  data: () => ({
    datetime: []
  }),
  methods: {
    onChange() {

      if(!this.datetime || this.datetime.length != 2) {
        this.$emit('change', []);
        return
      }

      var res = this.datetime.map(time => (time.format("yyyy-MM-DD HH:mm:ss")));

      this.$emit('change', res);
    }
  },
};
</script>


<style lang="scss">

.custom-date-picker-root {
  background-color: #fff;
  border: solid 1px #ccc;
  border-radius: 4px;
  height: 32px;
  overflow: hidden;
}
.custom-date-picker-root:hover {
  border-color: #2F54EB;
}
.custom-date-picker{
  border: 0;
  outline: 0;
  .ant-calendar-picker-input{
    border: 0;
    outline: 0;
  }
  .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month{
    background: #2F54EB;
  }

  .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date, .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date{
    background: #2F54EB;
  }
  .ant-calendar .ant-calendar-ok-btn{
    background-color: #2F54EB;
    border-color: #2F54EB;
    color: #fff;
  }
  .ant-calendar-time-picker-btn{
    color: #2F54EB;
  }
  .ant-calendar-range .ant-calendar-in-range-cell::before, .ant-calendar-date:hover{
    background: #2F54EB11
  }
  .ant-calendar-today .ant-calendar-date{
    border-color: #2F54EB;
    color: #2F54EB;
  }
}
</style>
