<template>
  <div class="search">
    <CustomTreeSelect :key="`ss-${key}`" @change="(val) => group = val"/>
    <CustomTreeSelectMulti :key="`sm-${key}`" @change="(val) => shop = val" style="margin-left: 20px;"/>
    <CustomDatePicker :key="`dp-${key}`" @change="(val) => month = val" style="margin-left: 20px;"/>

    <div style="flex: 1; min-width: 200px">
      <a-button style="float: right;" @click="onReset">重置</a-button>
      <a-button @click="onQuery" type="primary" style="float: right; margin-right: 20px;">
        查询
      </a-button>
    </div>
  </div>
</template>

<script>
import CustomTreeSelect from "./CustomTreeSelect.vue"
import CustomTreeSelectMulti from "./CustomTreeSelectMulti.vue"
import CustomDatePicker from "./CustomDatePicker.vue"
export default {
  components: {
    CustomTreeSelect,
    CustomTreeSelectMulti,
    CustomDatePicker,
  },
  data() {
    return {
      group: "",
      shop: "",
      month: "",

      key: 0
    }
  },
  methods: {
    onQuery(){
      this.$emit('onSearch', {
        group: this.group,
        shop: this.shop,
        month: this.month
      })
    },
    onReset(){
      this.group = "";
      this.shop = "";
      this.month = "";

      this.key = Date.now();
    },
  },
}
</script>

<style lang="scss" scoped>
.search{
  background-color: #fff;
  padding: 20px;
  display: flex;
  border-radius: 4px;
  .ant-btn-primary{
    background-color: #2f54eb;
    border-color: #2f54eb;
  }
  [class=ant-btn]:hover{
    color: #2f54eb;
    background-color: #fff;
    border-color: #2f54eb;
  }
}
</style>