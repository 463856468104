<template>
  <div class="satisfaction">
    <SearchCondition @onSearch="onSearch" style="margin-bottom: 16px;border-radius: 4px"/>
    <DatePicker @change="timeSelect" style="margin-bottom: 8px;"/>
    <white-card title="趋势分析">
      <BaseChart ref="chart" :chartOption="option" :height="'450px'"/>
    </white-card>
    <white-card title="总体分析">
      <BaseChart ref="chart1" :chartOption="option1" :height="'350px'"/>
    </white-card>
    <a-tabs type="card" @change="callback">
      <a-tab-pane v-for="item in tabs" :key="item.id" :tab="item.title">
        <InformationTabContent :id="item.id" :searchValue="searchValue">
          {{ item.title }}
        </InformationTabContent>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import WhiteCard from '../../../components/WhiteCard.vue';
import InformationTabContent from './components/InformationTabContent'
import BaseChart from "@/components/echarts/BaseChart";
import SearchCondition from "@/components/SearchCondition.vue"
import DatePicker from "@/components/DatePicker.vue"

const tabs = [
  {
    id: "8Ype1JxD",
    title: '售前咨询',
  },
  {
    id: "XEBB6kE2",
    title: '下单成功',
  },
  {
    id: "7xryV0Yp",
    title: '物流到货',
  },
  {
    id: "wYbZGoY4",
    title: '产品体验',
  },
  {
    id: "ox6am5Y8",
    title: '售后咨询',
  },
  {
    id: "lERm1JxB",
    title: '问题解决',
  },
  {
    id: "RxLamlE0",
    title: '评价跟进',
  },
]

export default {
  components: {BaseChart, WhiteCard, InformationTabContent, SearchCondition, DatePicker },
  data() {
    return {
      tabs,
      option:{},
      option1:{},
      searchValue:{
        group : '',
        shop : '',
        month : ['',''],
      },
      timeValue:{
        start: '',
        end: '',
      }
    };
  },
  methods: {
    callback(){
    },
    onSearch(value){
      this.searchValue = value;
      this.getChartData1();
      this.getChartData2();
    },
    timeSelect(value){
      this.timeValue = value;
      this.getChartData1();
    },
    getChartData1(){
      this.option = null;
      this.$axios.post('/api/dada/surprise_trend',{
        shop_name: this.searchValue.shop,
        start_time:  this.timeValue.start,
        end_time:  this.timeValue.end,
        complete_start_time: this.searchValue.month[0],
        complete_end_time: this.searchValue.month[1],
      }).then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          this.initOptions1(res.data.data);
        }
      })
    },
    getChartData2(){
      this.option1 = null;
      this.$axios.post('/api/dada/surprise_t2b_all',{
        complete_start_time: this.searchValue.month[0],
        complete_end_time: this.searchValue.month[1],
        shop_name: this.searchValue.shop,
        start_time:  this.searchValue.month[0],
        end_time:  this.searchValue.month[1],
        sn : '8Ype1JxD,XEBB6kE2,7xryV0Yp,wYbZGoY4,ox6am5Y8,lERm1JxB,RxLamlE0',
      }).then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          const result = res.data.data.findIndex(item => item.rate - 0 > 0)
          if(result !== -1){
            this.initOptions2(res.data.data);
          }
        }
      })
    },
    initOptions2(data){
      let xarr = [];
      let yarr = [];
      data.map(item => {
        xarr.push(item.survey_name);
        yarr.push(item.rate);
      })
      this.option1 = {
        grid: {
          top: 30,
          left: 50,
          right: 50,
          height: 300,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          valueFormatter: function (value) {
            // return `${(Number((value * 100).toFixed(2)) )}%`;
            return value + ' %';
          },
        },
        xAxis: {
          type: 'category',
          data: xarr,
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: `{value} %`,
            // formatter: function (value) {
            //   return `${(Number((value * 100).toFixed(2)) )}%`;
            // },
          },
        },
        series: [
          {
            data: yarr,
            type: 'bar',
            barMaxWidth: '100',
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#6BC6F7' },
              { offset: 1, color: '#5981F7' },
            ]),
            label: {
              show: true,
              position: 'top',
              formatter: function (value) {
                return value.value + ' %';
                // return `${(Number((value.value * 100).toFixed(2)) )}%`;
              },
            },
          }
        ]
      };
    },
    initOptions1(data){
      let allMonth = [];
      let names = [];
      let seriesData = [];
      data.map(item => {
        Object.keys(item.valueMap).map(i => {
          if(!allMonth.includes(i)){
            allMonth.push(i);
          }
        })
      });
      allMonth.sort((old,New)=>{
        return (old.replace("-",'') - 0) - (New.replace("-",'') - 0)
      })
      data.map(item => {
        let itemData = [];
        allMonth.map(month => {
          if(item.valueMap[month]){
            itemData.push(item.valueMap[month])
          }else{
            itemData.push('0');
          }
        })
        names.push(item.shopName);
        let sitem = {
          name: item.shopName,
          data: itemData,
          type: 'line',
          symbolSize: 8,
          // formatter: function (value) {
          //   return `${(Number((value.value * 100).toFixed(2)) )}%`;
          // },
          smooth: true,
          // areaStyle: {
          // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          //   { offset: 0, color: '#5981F7' },
          //   { offset: 1, color: '#6BC6F7' }
          // ]),
          // }
        };
        seriesData.push(sitem);
      });
      this.option = {
        grid: {
          top: 30,
          left: 50,
          right: 50,
          height: 300,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            },
          },
          valueFormatter: function (value) {
            return value + ' %';
            // return `${(Number((value * 100).toFixed(2)) )}%`;
          },
        },
        legend: {
          data: names,
          bottom: '5%',
        },
        xAxis: {
          type: 'category',
          // show: false,
          data: allMonth,
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: `{value} %`,
            // formatter: function (value) {
            //   return `${(Number((value * 100).toFixed(2)) )}%`;
            // },
          },
        },
        series: seriesData,
      };
    }
  },
  mounted() {
    this.getChartData1();
    this.getChartData2();
  },
};
</script>

<style lang="scss" scoped>
.satisfaction {
  padding: 16px;

  ::v-deep .ant-tabs-bar{
    margin: 0 0 0 0;
  }
  ::v-deep .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab{
    border-radius: 0 0 0 0;
    margin-right: 0px;
    background: #F7F9FC;
    height: 48px;
    line-height: 32px;
    padding: 8px 24px 8px 24px;
  }
  ::v-deep .ant-tabs-nav-scroll{
    background: #ffffff;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    div:first-child{
      div:first-child{
        border-top-left-radius: 4px !important;
      }
    }
  }
  ::v-deep .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active{
    background: #ffffff;
    color: #5980F7;
  }
  ::v-deep .ant-tabs-nav-container{
    font-size: 14px;
    color: #333333;
    height: 48px !important;
  }

  ::v-deep .month{
    background: #ffffff;
  }
}

</style>
