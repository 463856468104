<template>
  <div class="date-picker">
    <div class="month">月份</div>
    <a-range-picker
      :value="value"
      @change="handleChange"
      @panelChange="handlePanelChange"
      @openChange="handleOpenChange"
      :placeholder="['开始日期', '结束日期']"
      :mode="['month', 'month']"
      format="YYYY年MM月"
      separator="-"
    >
      <a-icon slot="suffixIcon" type="calendar" />
    </a-range-picker>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: [],
    };
  },

  methods: {
    handleChange(value) {
      this.value = value;
      this.onChange();
    },
    handlePanelChange(value) {
      this.value = value;
      // this.onChange(); // 如果需要实时改变，打开即可
    },
    handleOpenChange(status) {
      if (status) return;
      this.onChange();
    },
    onChange() {
      this.$emit('change', {
        start: this.value[0]?.format('YYYY-MM'),
        end: this.value[1]?.format('YYYY-MM'),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.date-picker {
  display: flex;
  align-items: center;

  .month {
    width: 56px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #cccccc;
    font-size: 16px;
    font-weight: 400;
    color: #3d3d3d;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
  }
}

:deep(.ant-input) {
  border-color: #cccccc;
}
</style>

<style lang="scss">
.ant-calendar-header a:hover {
  color: rgba(47, 84, 235, 0.8);
}

.ant-calendar-month-panel-month:hover {
  background: rgba(230, 247, 255, 0.4);
}

.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
  background: #2f54eb !important;
}
</style>
